class Wcag {
    body;
    fontSizeButtonActiveClass = 'accessibility-container__font-button--active';
    contrastButtonActiveClass = 'accessibility-container__contrast-button--active';
    fontSizeButtons;
    fontSizeButtonSm;
    fontSizeButtonLg;
    fontSizeButtonXl;
    contrastButton;
    languageButton;
    fontSizeCookieName = 'leaffs';
    contrastCookieName = 'leafcon';

    constructor() {
        this.body = document.querySelector('body');
        this.fontSizeButtons = document.querySelectorAll('.accessibility-container__font-button');
        this.fontSizeButtonSm = document.querySelector('.accessibility-container__font-button--sm');
        this.fontSizeButtonLg = document.querySelector('.accessibility-container__font-button--lg');
        this.fontSizeButtonXl = document.querySelector('.accessibility-container__font-button--xl');
        this.contrastButton = document.querySelector('.accessibility-container__contrast-button');
        this.languageButton = document.querySelector('.lang-switcher__current-container');
        this.initialize();
    }

    initialize() {
        this.initializeBodyClasses();
        this.initializeContrastButton();
        this.initializeFontSizeButtons();
        this.fixMobileFontSize();
        this.initializeLanguageSwitcher();
    }

    getCookieValue(name) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }

    setCookieValue(name, value, days = 365) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=0; path=/;';
    }

    fixMobileFontSize() {
        let current = this;

        current.removeFontSizeClassesOnMobile();
        current.removeFontSizeCookieOnMobile();

        window.addEventListener('resize', function () {
            current.removeFontSizeClassesOnMobile();
            current.removeFontSizeCookieOnMobile();
        });
    }

    removeFontSizeClassesOnMobile() {
        let width = this.body.clientWidth;

        if (width < 1200) {
            this.body.classList.add('body--fs-0');
            this.body.classList.remove('body--fs-1');
            this.body.classList.remove('body--fs-2');
            this.fontSizeButtonLg.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonXl.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonSm.classList.add(this.fontSizeButtonActiveClass);
        }
    }

    removeFontSizeCookieOnMobile() {
        let width = this.body.clientWidth;

        if (width < 1200) {
            this.eraseCookie(this.fontSizeCookieName);
        }
    }

    initializeBodyClasses() {
        this.updateBodyFontSizeClass(this.getCookieValue(this.fontSizeCookieName));
        this.updateBodyContrastClass(this.getCookieValue(this.contrastCookieName) === '1');
    }

    updateBodyFontSizeClass(fontSize) {
        if (fontSize === '0' || fontSize === false || fontSize === undefined || fontSize === '') {
            this.body.classList.remove('body--fs-1');
            this.body.classList.remove('body--fs-2');
            this.body.classList.add('body--fs-0');
            this.fontSizeButtonLg.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonXl.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonSm.classList.add(this.fontSizeButtonActiveClass);
        } else if (this.getCookieValue(this.fontSizeCookieName) === '1') {
            this.body.classList.remove('body--fs-0');
            this.body.classList.remove('body--fs-2');
            this.body.classList.add('body--fs-1');
            this.fontSizeButtonSm.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonXl.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonLg.classList.add(this.fontSizeButtonActiveClass);
        } else if (this.getCookieValue(this.fontSizeCookieName) === '2') {
            this.body.classList.remove('body--fs-0');
            this.body.classList.remove('body--fs-1');
            this.body.classList.add('body--fs-2');
            this.fontSizeButtonSm.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonLg.classList.remove(this.fontSizeButtonActiveClass);
            this.fontSizeButtonXl.classList.add(this.fontSizeButtonActiveClass);
        }
    }

    updateBodyContrastClass(isContrast) {
        if (isContrast) {
            this.body.classList.add('body--contrast');
            this.contrastButton.setAttribute('aria-label', this.contrastButton.dataset.contrastOff);
            this.contrastButton.classList.add(this.contrastButtonActiveClass);
        } else {
            this.body.classList.remove('body--contrast');
            this.contrastButton.setAttribute('aria-label', this.contrastButton.dataset.contrastOn);
            this.contrastButton.classList.remove(this.contrastButtonActiveClass);
        }
    }

    initializeContrastButton() {
        let current = this;

        this.contrastButton.addEventListener('click', function (e) {
            e.preventDefault();

            if (current.body.classList.contains('body--contrast')) {
                current.body.classList.remove('body--contrast');
                current.eraseCookie(current.contrastCookieName);
                this.setAttribute('aria-label', this.dataset.contrastOn);
                this.classList.remove(current.contrastButtonActiveClass);
            } else {
                current.body.classList.add('body--contrast');
                current.setCookieValue(current.contrastCookieName, '1');
                this.setAttribute('aria-label', this.dataset.contrastOff);
                this.classList.add(current.contrastButtonActiveClass);
            }
        });
    }

    removeActiveClassFromFontSizeButtons() {
        this.fontSizeButtons.forEach(item => {
            item.classList.remove(this.fontSizeButtonActiveClass);
        });
    }

    initializeFontSizeButtons() {
        let current = this;

        this.fontSizeButtonSm.addEventListener('click', function (e) {
            e.preventDefault();

            current.removeActiveClassFromFontSizeButtons();
            this.classList.add(current.fontSizeButtonActiveClass);

            if (current.body.classList.contains('body--fs-1') || current.body.classList.contains('body--fs-2')) {
                current.body.classList.remove('body--fs-1');
                current.body.classList.remove('body--fs-2');
                current.eraseCookie(current.fontSizeCookieName)
            }

            current.body.classList.add('body--fs-0');
        });

        this.fontSizeButtonLg.addEventListener('click', function (e) {
            e.preventDefault();

            current.removeActiveClassFromFontSizeButtons();
            this.classList.add(current.fontSizeButtonActiveClass);

            if (current.body.classList.contains('body--fs-0') || current.body.classList.contains('body--fs-2')) {
                current.body.classList.remove('body--fs-0');
                current.body.classList.remove('body--fs-2');
            }

            current.body.classList.add('body--fs-1');
            current.setCookieValue(current.fontSizeCookieName, '1');
        });

        this.fontSizeButtonXl.addEventListener('click', function (e) {
            e.preventDefault();

            current.removeActiveClassFromFontSizeButtons();
            this.classList.add(current.fontSizeButtonActiveClass);

            if (current.body.classList.contains('body--fs-0') || current.body.classList.contains('body--fs-1')) {
                current.body.classList.remove('body--fs-0');
                current.body.classList.remove('body--fs-1');
            }

            current.body.classList.add('body--fs-2');
            current.setCookieValue(current.fontSizeCookieName, '2');
        });
    }

    initializeLanguageSwitcher() {
        this.languageButton.addEventListener('click', function (e) {
            e.preventDefault();
        });
    }
}

function initializeWcag() {
    const wcag = new Wcag();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeWcag);
} else {
    initializeWcag();
}